import { useState, useEffect, useRef, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import TextField from "../fields/TextField";
import { getSectionName } from "../modules/business";
import PdfModal from "../widgets/PdfModal";
import VideoModal from "../widgets/VideoModal";
import TenantContext from "../../context/tenant";

type PdfType = {
  file: string;
  page: number;
  scale?: string;
};

export default function Learn({ isModal }: { isModal: boolean }) {
  const navigate = useNavigate();
  const { mediaItems, libraryItems } = useContext(TenantContext);

  const [search, setSearch] = useState({ query: "" });
  const [searchParams, setSearchParams] = useSearchParams();
  const mediaParam = searchParams.get("media");
  const pdfParam = searchParams.get("pdf");
  const pageParam = searchParams.get("page") || "1";
  const section = searchParams.get("section");

  const [openTab, setOpenTab] = useState<number>(
    parseInt(searchParams.get("tab") || "1"),
  );

  const [filteredList, setFilteredList] = useState(mediaItems);
  const [currentMedia, setCurrentMedia] = useState<string | null>(null);
  const [currentPdf, setCurrentPdf] = useState<PdfType | null>(null);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);

  function handleChange(event: any) {
    const value = event.target.value;

    setSearch({ query: value });

    if (!value) {
      setFilteredList({ ...mediaItems });
    }

    if (!mediaItems) {
      return;
    }

    if (value) {
      setFilteredList(
        Object.assign(
          {},
          ...Object.keys(mediaItems).map((name) => {
            return {
              [name]: mediaItems[name].filter(
                (i) =>
                  i.title.toLowerCase().includes(value.toLowerCase()) ||
                  i.description?.toLowerCase()?.includes(value.toLowerCase()),
              ),
            };
          }),
        ),
      );
    }
  }

  function handleClick(content: {
    pdf?: PdfType;
    video?: string;
    link?: string;
  }) {
    if (content.pdf) {
      handlePdfClick(content.pdf);
    }

    if (content.video) {
      handleMediaClick(content.video);
    }

    if (content.link) {
      handleLinkClick(content.link);
    }
  }

  function handleMediaClick(video: string) {
    if (isModal) {
      navigate(`/help?media=${video}`);
    } else {
      openMediaModal(video);
    }
  }

  function handlePdfClick(pdf: PdfType) {
    if (isModal) {
      navigate(`/help?pdf=${pdf.file}&page=${pdf.page}&scale=${pdf.scale}`);
    } else {
      openPdfModal(pdf);
    }
  }

  function handleLinkClick(link: string) {
    // window.location.href = link;
    window.open(link, '_blank')?.focus();
  }

  function openMediaModal(video: string) {
    setCurrentMedia(video);
    setShowMediaModal(true);
  }

  function openPdfModal(pdf: PdfType) {
    setCurrentPdf(pdf);
    setShowPdfModal(true);
  }

  function closeModals() {
    setShowPdfModal(false);
    setCurrentPdf(null);

    setShowMediaModal(false);
    setCurrentMedia(null);
  }

  function setTab(newTab: number) {
    setOpenTab(newTab);
    setSearchParams({ tab: `${newTab}` });
  }

  useEffect(() => {
    if (mediaParam) {
      openMediaModal(mediaParam);
      window.history.replaceState({}, document.title, "/help");
    }

    if (pdfParam) {
      openPdfModal({ file: pdfParam, page: parseInt(pageParam) });
      window.history.replaceState({}, document.title, "/help");
    }
  }, []);

  return (
    <div
      className={(isModal ? "mx-8" : "mx-auto") + " flex max-w-7xl flex-col"}
    >
      <div className="modals">
        {currentMedia && (
          <VideoModal
            show={showMediaModal}
            handleClose={closeModals}
            videoSource={currentMedia}
            autoplay={true}
          />
        )}
        {currentPdf && (
          <PdfModal
            pdf={currentPdf}
            showModal={showPdfModal}
            handleClose={closeModals}
          />
        )}
      </div>

      <ul
        className="mx-1 mb-0 flex list-none flex-row flex-wrap pb-4 pt-3"
        role="tablist"
      >
        <li className="-mb-px mr-2 mt-2 flex-auto text-center last:mr-0 cursor-pointer">
          <a
            className={
              "block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg " +
              (openTab === 1
                ? "bg-primary text-white"
                : "bg-white text-primary")
            }
            onClick={(e) => {
              e.preventDefault();
              setTab(1);
            }}
            data-toggle="tab"
            href="#faq"
            role="tablist"
          >
            FAQ
          </a>
        </li>
        <li className="-mb-px mr-2 mt-2 flex-auto text-center last:mr-0 cursor-pointer">
          <a
            className={
              "block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg " +
              (openTab === 2
                ? "bg-primary text-white"
                : "text-bg-primary bg-white")
            }
            onClick={(e) => {
              e.preventDefault();
              setTab(2);
            }}
            data-toggle="tab"
            href="#library"
            role="tablist"
          >
            General Library
          </a>
        </li>
      </ul>

      <div className="-my-2 overflow-x-auto">
        <div className="tab-content tab-space overflow-auto">
          <div
            className={(openTab === 1 ? "block" : "hidden") + " mt-6"}
            id="faq"
          >
            <div className="relative -mb-12 mr-4 h-20">
              <div className="absolute right-0 top-0">
                <TextField
                  name="Search"
                  slug="query"
                  value={search}
                  handleChange={handleChange}
                  width="80"
                />
              </div>
            </div>

            <div className="mt-12 sm:mt-0">
              {filteredList &&
                Object.keys(filteredList).map((section, i) => {
                  return (
                    <div
                      key={section}
                      className="inline-block min-w-full px-4 py-2 align-middle"
                    >
                      <div id={section} className="pt-1 text-gray-900">
                        <h3 className="pb-4 text-lg font-medium leading-6">
                          {getSectionName(section)}
                        </h3>
                        <div className="mb-8 border-t border-gray-200"></div>
                      </div>

                      <div className="mb-10 overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-100">
                            <tr>
                              <th
                                scope="col"
                                className="w-5/6 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Question
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Content
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {filteredList[section].map((content, j) => {
                              return (
                                <tr key={section + "_content_" + j}>
                                  <td className="px-6 py-4">
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => handleClick(content)}
                                    >
                                      <div className="text-sm font-medium text-gray-900">
                                        {content.title}
                                      </div>
                                      {/* {content.description && <div className="text-sm text-gray-500 w-3/4 mt-1">{content.description}</div>} */}
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    {content.pdf && (
                                      <span className="mr-2 rounded bg-red-100 px-2.5 py-0.5 text-xs font-semibold text-red-800 dark:bg-red-200 dark:text-red-900">
                                        PDF
                                      </span>
                                    )}
                                    {content.video && (
                                      <span className="mr-2 rounded bg-blue-100 px-2.5 py-0.5 text-xs font-semibold text-blue-800 dark:bg-blue-200 dark:text-blue-800">
                                        Video
                                      </span>
                                    )}
                                    {content.link && (
                                      <span className="mr-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-semibold text-green-800 dark:bg-green-200 dark:text-green-800">
                                        Link
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className={openTab === 2 ? "block" : "hidden"} id="library">
            <div className="mt-12">
              <div className="inline-block min-w-full px-4 py-2 align-middle">
                <div id="library" className="pt-1 text-gray-900">
                  <h3 className="pb-4 text-lg font-medium leading-6">
                    Library
                  </h3>
                  <div className="mb-8 border-t border-gray-200"></div>
                </div>

                <div className="mb-10 overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          className="w-5/6 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Question
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Content
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {libraryItems &&
                        libraryItems.map((content, j) => {
                          return (
                            <tr key={section + "_content_" + j}>
                              <td className="px-6 py-4">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => handleClick(content)}
                                >
                                  <div className="text-sm font-medium text-gray-900">
                                    {content.title}
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                {content.pdf && (
                                  <span className="mr-2 rounded bg-red-100 px-2.5 py-0.5 text-xs font-semibold text-red-800 dark:bg-red-200 dark:text-red-900">
                                    PDF
                                  </span>
                                )}
                                {content.video && (
                                  <span className="mr-2 rounded bg-blue-100 px-2.5 py-0.5 text-xs font-semibold text-blue-800 dark:bg-blue-200 dark:text-blue-800">
                                    Video
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
