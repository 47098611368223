import { useEffect, useState, useRef, useContext } from "react";
import ReactTooltip from "react-tooltip";

import {
  listHasRecords,
  getListFieldTotal,
  printBirthday,
  getEmergencyReserves,
  getRetirementAccountType,
  getRetirementContribution,
  getTopInvestmentAccount,
  getInvestmentAssets,
  roundDollars,
  getAge,
} from "./modules/business";

import "./CircleDiagram.css";
import TenantContext from "../context/tenant";
import useFormData from "../lib/use-form-data";
import useQueryData from "../lib/use-query-data";
import {
  FormDataAdditionalPropertyType,
  FormDataInvestmentAccountsType,
} from "../lib/types";
import DiagramStudentLoanSection from "./DiagramStudentLoanSection";

function CircleDiagram() {
  const { id, employeeName } = useContext(TenantContext);
  const [hideAssetsLiabilities, setHideAssetsLiabilities] = useState(false);
  const [hideFutureIncome, setHideFutureIncome] = useState(false);
  const [style, setStyle] = useState({});
  const { formData: formValues } = useFormData();

  const {
    queryData: { results: progressVesting },
  } = useQueryData(
    "dc_progress_vesting", id === "1"
  );
  

  const circleOneRef = useRef<any | null>(null);

  const assetsLiabilitiesRef = useRef<any | null>(null);

  const futureOneRef = useRef<any | null>(null);

  useEffect(() => {
    const circleOneBottom =
      circleOneRef.current.offsetTop + circleOneRef.current.offsetHeight;
    const assetsLiabilitiesRefBottom =
      assetsLiabilitiesRef.current.offsetTop +
      assetsLiabilitiesRef.current.offsetHeight;
    const futureOneRefBottom =
      futureOneRef.current.offsetTop + futureOneRef.current.offsetHeight;

    if (assetsLiabilitiesRefBottom > circleOneBottom) {
      setHideAssetsLiabilities(true);
    }
    if (futureOneRefBottom > circleOneBottom) {
      setHideFutureIncome(true);
    }

    const leftMargin = circleOneRef.current.offsetLeft;
    const windowWidth = window.innerWidth;
    const completeWidth = circleOneRef.current.offsetWidth;

    const marginLeft = (completeWidth - windowWidth) / 2 + leftMargin;

    if (completeWidth > windowWidth) {
      setStyle({ marginLeft: -marginLeft });
    }
  }, []);

  const topInvestmentAccount: FormDataInvestmentAccountsType =
    getTopInvestmentAccount(
      formValues["investmentAccounts"],
      "You",
    ) as unknown as FormDataInvestmentAccountsType;
  const investmentAssets = getInvestmentAssets(
    formValues["investmentAccounts"],
    "You",
  );
  const retirementContribution = getRetirementContribution(
    formValues["retirementContributionPreTax"],
    formValues["retirementContributionPostTax"],
  );

  return (
    <div className={`tennant-${id}`}>
      <div
        ref={circleOneRef}
        style={style}
        className="circlediagram mx-auto my-8 uppercase text-gray-500"
      >
        <h3 className="first text-md font-bold">Current Income</h3>

        <p>
          <data>{formValues["currentEmployment"]}</data>
          <data>
            {employeeName}&nbsp;- {roundDollars(formValues["income"])}
          </data>
          {id === "1" &&
            getAge(formValues["birthdate"]) > 64 &&
            (formValues["hasGreensheet"] == "Yes" ||
              formValues["hasGreensheetEstimate"] == "Yes" ||
              formValues["hasPeraStatement"] == "Yes" ||
              formValues["hasPeraEstimate"] == "Yes") && (
              <data>
                MNA PENSION BENEFIT -{" "}
                {roundDollars(!!progressVesting && !!progressVesting["lifeOnlyBenefit65"] ? progressVesting["currentBenefit"] : 0)}/Mo
              </data>
            )}
        </p>

        <h3 className="text-md font-bold">Retirement Plans</h3>

        <div className="mt-2">
          <div className="mx-2 inline-block w-56">
            <p>
              <data>{formValues["currentEmployment"]}</data>
              <data>
                {getRetirementAccountType(formValues["currentEmployment"])}-{" "}
                {roundDollars(formValues["retirementBalance"])}
              </data>
              <data className="text-xs italic">
                Contribution: {retirementContribution}%
              </data>
            </p>
          </div>

          {!!topInvestmentAccount && (
            <div className="mx-2 inline-block w-56">
              <p>
                <data>{topInvestmentAccount.investmentAccountName}</data>
                <data>
                  {topInvestmentAccount.investmentAccountType}-{" "}
                  {roundDollars(topInvestmentAccount.investmentBalance)}
                </data>
                <data className="text-xs italic">
                  Contribution:{" "}
                  {roundDollars(topInvestmentAccount.investmentContribution)}
                </data>
              </p>
            </div>
          )}
        </div>

        <div className={`namediv text-primary`}>
          <h3 className="text-md font-bold">{formValues["nameFirst"]}</h3>
          <p>
            <data className="font-bold">{formValues["nameLast"]}</data>
            <data>{printBirthday(formValues["birthdate"])}</data>
          </p>
        </div>

        <div
          ref={futureOneRef}
          className={`${hideFutureIncome ? "hide-div" : ""}`}
        >
          <h3 className="text-md font-bold">Future Income</h3>
          <div className="mt-2">
            <div className="mx-1 inline-block w-72 align-top">
              {!!formValues["socialSecurityAmountRetirementAge"] ? (
                <p>
                  <data>
                    Social Security (FRA: {formValues["socialSecurityAge"]})
                  </data>
                  <data>
                    {formValues["socialSecurityAmountPartial"] &&
                      ` @62 - ${roundDollars(
                        formValues["socialSecurityAmountPartial"],
                      )}, `}
                    @{formValues["socialSecurityAge"]} -{" "}
                    {roundDollars(
                      formValues["socialSecurityAmountRetirementAge"],
                    )}
                    {formValues["socialSecurityAmountFull"] &&
                      `, @70 - ${roundDollars(
                        formValues["socialSecurityAmountFull"],
                      )}`}
                  </data>
                </p>
              ) : (
                <p>
                  <data>Social Security TBD</data>
                </p>
              )}
            </div>

            {id === "1" && (
              <div className="mx-1 inline-block w-72">
                {formValues["currentEmployment"] != "HCMC" &&
                  formValues["currentEmployment"] != "Essentia Health" &&
                  (formValues["hasGreensheet"] == "Yes" ||
                    formValues["hasGreensheetEstimate"] == "Yes") &&
                  getAge(formValues["birthdate"]) < 65 && (
                    <p className="pension">
                      <data data-tip data-for="currentBenefitTip">
                        MNA Pension
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-grey inline h-5 w-5 pb-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </data>
                      <data>
                        Est. Life Only @ Rule of 85 ({progressVesting ? progressVesting["vestingAge"] : formValues["vestingAge"]}) -{" "}
                        {progressVesting && !!progressVesting["lifeOnlyBenefit"]
                          ? roundDollars(progressVesting["lifeOnlyBenefit"])
                          : "$0"}
                      </data>
                      {((formValues["employmentPlanToStay"] &&
                        formValues["employmentPlanToStay"] == "Yes") ||
                        (formValues["employmentPlanToStayMna"] &&
                          formValues["employmentPlanToStayMna"] == "Yes")) && (
                        <data>
                          Est. Life Only @65{" "}
                          {roundDollars(!!progressVesting && !!progressVesting["lifeOnlyBenefit65"] ? progressVesting["lifeOnlyBenefit65"] : 0)}
                        </data>
                      )}
                      {formValues["employmentPlanToStay"] &&
                        formValues["employmentPlanToStay"] == "No" &&
                        formValues["employmentPlanToStayMna"] &&
                        formValues["employmentPlanToStayMna"] == "No" && (
                          <data>
                            Est. Life Only Currently{" "}
                            {roundDollars(!!progressVesting && !!progressVesting["lifeOnlyBenefit65"] ? progressVesting["currentBenefit"] : 0)}
                          </data>
                        )}
                    </p>
                  )}

                {formValues["currentEmployment"] != "HCMC" &&
                  formValues["currentEmployment"] != "Essentia Health" &&
                  formValues["hasGreensheet"] != "Yes" &&
                  formValues["hasGreensheetEstimate"] != "Yes" &&
                  getAge(formValues["birthdate"]) < 65 && (
                    <p className="pension">
                      <data>MNA Pension TBD</data>
                    </p>
                  )}

                {formValues["currentEmployment"] == "HCMC" &&
                  (formValues["hasPeraStatement"] == "Yes" ||
                    formValues["hasPeraEstimate"] == "Yes") &&
                  getAge(formValues["birthdate"]) < 65 && (
                    <p className="pension">
                      <data>PERA Pension</data>
                      <data>
                        Est. Life Only @66 -{" "}
                        {roundDollars(formValues["peraEstimatedBenefitAt66"])}
                      </data>
                    </p>
                  )}

                {formValues["currentEmployment"] == "HCMC" &&
                  getAge(formValues["birthdate"]) < 65 &&
                  formValues["hasPeraStatement"] != "Yes" &&
                  formValues["hasPeraEstimate"] != "Yes" && (
                    <p className="pension">
                      <data>PERA Pension TBD</data>
                    </p>
                  )}
              </div>
            )}
          </div>
        </div>

        <div
          ref={assetsLiabilitiesRef}
          className={`${hideAssetsLiabilities ? "hide-div" : ""}`}
        >
          <div className="mt-4 hidden sm:block">
            <div className="mx-1 inline-block w-56 align-top">
              <p>
                <data className="heading text-lg font-bold">Assets</data>
                <data className="mt-1">
                  Emergency Reserves -{" "}
                  {getEmergencyReserves(
                    formValues["checkingAccountBalance"],
                    formValues["savingAccountBalance"],
                    formValues["otherAccountBalance"],
                  )}
                </data>
                {!!formValues["homeEstimatedValue"] && (
                  <data className="mt-1">
                    Home - {roundDollars(formValues["homeEstimatedValue"])}
                  </data>
                )}
                {!!investmentAssets && (
                  <data className="mt-1">
                    Investments - {roundDollars(investmentAssets)}
                  </data>
                )}
              </p>

              {listHasRecords(formValues["additionalProperties"], [
                "additionalPropertyType",
              ]) &&
                formValues.additionalProperties
                  ?.slice(0, 1)
                  .map(
                    (
                      property: FormDataAdditionalPropertyType,
                      index: number,
                    ) => {
                      if (!property.additionalPropertyType) {
                        return;
                      }

                      return (
                        <p
                          className="mt-1 text-sm"
                          key={`${index}_additionalProperties`}
                        >
                          <data>
                            {property.additionalPropertyType + " Property"}-{" "}
                            {roundDollars(property.additionalPropertyValue)}
                          </data>
                        </p>
                      );
                    },
                  )}
            </div>

            <div className="mx-1 inline-block w-56">
              <p>
                <data className="heading text-lg font-bold">Liabilities</data>
                {!!formValues["mortgageAmount"] && (
                  <>
                    <data className="mt-1">
                      Mortgage - {roundDollars(formValues["mortgageAmount"])}
                    </data>
                    <data>
                      {roundDollars(formValues["mortgagePayment"])}/Mo ,{" "}
                      {formValues["mortgageInterestRate"] || 0}%
                    </data>
                  </>
                )}
              </p>

              {listHasRecords(formValues["debtsCreditCard"], [
                "creditCardName",
              ]) && (
                <p className="mt-1 text-sm">
                  <data>
                    Credit Card Debt -{" "}
                    {getListFieldTotal(
                      formValues["debtsCreditCard"],
                      "creditCardBalance",
                    )}
                  </data>
                </p>
              )}

              {listHasRecords(formValues["debtsAutoLoans"], ["autoLoanName"]) && (
                <p className="mt-1 text-sm">
                  <data>
                    Car Loan -{" "}
                    {getListFieldTotal(
                      formValues["debtsAutoLoans"],
                      "autoLoanBalance",
                    )}
                  </data>
                  <data>
                    {getListFieldTotal(
                      formValues["debtsAutoLoans"],
                      "autoLoanPayment",
                    )}{" "}
                    PMT
                  </data>
                </p>
              )}

              <DiagramStudentLoanSection formValues={formValues} />

            </div>
          </div>
        </div>
      </div>

      <ReactTooltip
        id="currentBenefitTip"
        place="top"
        effect="solid"
        multiline={true}
      >
        {/* The Amount you would receive if you leave today. <br /> */}
        Any estimated MNA pension benefits in this application are for
        informational <br />
        purposes only to help illustrate the potential value of your MNA
        pension. <br />
        The calculations assume you continue working in the MNA contract to the
        age <br />
        of collecting benefits and earn credits roughly equal to your most
        recent <br />
        benefit amount for that entire time. Your actual benefit amount will be{" "}
        <br />
        determined by your work history and will be provided to you by Wilson
        McShane. <br />
      </ReactTooltip>
    </div>
  );
}

export default CircleDiagram;
