import TenantContext, { TenantContextType } from "../context/tenant";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import StyledBounceLoader from "../components/StyledBounceLoader";

const TenantGlobalStyles = createGlobalStyle`
  :root {
    --theme-color: ${(props) => props.theme.color};
    --theme-light: ${(props) => props.theme.light};
    //TODO are these used?
    --theme-dark: ${(props) => props.theme.dark};;
    --theme-forest: #1b652e;
  }
`;

type TenantConfiguration = {
  config: TenantContextType;
  theme: any;
};

const TenantProvider = ({ children }: any): JSX.Element => {
  const [tenant, setTenant] = useState<TenantConfiguration | null>(null);
  const tenantInfo = useMemo(() => tenant, [tenant]);

  useEffect(() => {
    const loadTenantData = async (): Promise<void> => {
      const x: TenantConfiguration =
        import.meta.env.VITE_TENANT_ID === "1"
          ? await import("../machines/mna.json")
        : import.meta.env.VITE_TENANT_ID === "2" 
          ? await import("../machines/hbff.json") 
        : import.meta.env.VITE_TENANT_ID === "3" 
          ? await import("../machines/gre.json")
        // default to mna for now
          : await import("../machines/mna.json")
      setTenant(x);
    };

    loadTenantData();
  });

  return tenantInfo ? (
    <TenantContext.Provider value={tenantInfo.config}>
      <ThemeProvider theme={tenantInfo.theme}>
        <TenantGlobalStyles />
        {children}
      </ThemeProvider>
    </TenantContext.Provider>
  ) : (
    <div className="mx-auto my-16 max-w-5xl">
      <div className="flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    </div>
  );
};

export default TenantProvider;
