import { BounceLoader } from "react-spinners";

import TextField from "../fields/TextField";
import { useContext } from "react";
import TenantContext from "../../context/tenant";

export default function FinanceForm(props) {
  const { id } = useContext(TenantContext);
  // TODO: change values to formValues for consistency
  let fieldValidations = {
    greensheetAmount: "optional|dollars",
    greensheetService: "optional|between:0-60|numeric",
    greensheetPensionCredit: "optional|dollars",
    socialSecurityAmountPartial: "optional|dollars",
    socialSecurityAmountRetirementAge: "optional|dollars",
    socialSecurityAmountFull: "optional|dollars",
    retirementContribution: "optional|between:0-100|numeric",
    retirementContributionStockAllocation: "optional",
    retirementContributionBondAllocation: "optional",
    retirementBalance: "optional|dollars",
    spouseRetirementContributionStockAllocation: "optional",
    checkingAccountBalance: "optional|dollars",
    savingAccountBalance: "optional|dollars",
    checkingAndSavingCleared: "optional|dollars",
  };

  function handleSubmit(event) {
    event.preventDefault();
    props.handleSubmit(fieldValidations);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="form-div overflow-hidden shadow sm:rounded-md"
    >
      <div className="bg-white p-6 pb-12">
        {id === "1" && props.values["currentEmployment"] != "HCMC" && (
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Pension
              </h3>
              <div className="mt-2 border-t border-gray-200"></div>
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Greensheet Year"
                slug="greensheetYear"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Benefit Amount"
                slug="greensheetAmount"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Vested Years of Service"
                slug="greensheetService"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Last Credit"
                slug="greensheetPensionCredit"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>
          </div>
        )}

        {id === "1" && props.values["currentEmployment"] == "HCMC" && (
          <div className="grid grid-cols-8 gap-6">
            <div className="col-span-8">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Pension
              </h3>
              <div className="mt-2 border-t border-gray-200"></div>
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Member contribution"
                slug="peraMemberContributions"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Benefit amount at 66"
                slug="peraEstimatedBenefitAt66"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Service credits"
                slug="peraServiceCredits"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
              />
            </div>

            <div className="col-span-0 sm:col-span-2"></div>
          </div>
        )}

        {id === "1" &&
          props.values["spouseHasCurrentEmployment"] == "Yes" &&
          props.values["spouseEmployment"] != "HCMC" && (
            <div className="grid grid-cols-8 gap-6 pt-4">
              <div className="col-span-8 sm:col-span-2">
                <TextField
                  name="Spouse Greensheet Year"
                  slug="spouseGreensheetYear"
                  value={props.values}
                  handleChange={props.handleChange}
                  errors={props.errors}
                />
              </div>

              <div className="col-span-8 sm:col-span-2">
                <TextField
                  name="Spouse Benefit Amount"
                  slug="spouseGreensheetAmount"
                  value={props.values}
                  handleChange={props.handleChange}
                  errors={props.errors}
                  format="dollars"
                />
              </div>

              <div className="col-span-8 sm:col-span-2">
                <TextField
                  name="Spouse Years of Service"
                  slug="spouseGreensheetService"
                  value={props.values}
                  handleChange={props.handleChange}
                  errors={props.errors}
                />
              </div>

              <div className="col-span-8 sm:col-span-2">
                <TextField
                  name="Spouse Last Credit"
                  slug="spouseGreensheetPensionCredit"
                  value={props.values}
                  handleChange={props.handleChange}
                  errors={props.errors}
                  format="dollars"
                />
              </div>

              <div className="col-span-0 sm:col-span-2"></div>
            </div>
          )}

        {id === "1" && props.values["spouseEmployment"] == "HCMC" && (
          <div className="grid grid-cols-8 gap-6 pt-4">
            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse member contribution"
                slug="spousePeraMemberContributions"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse benefit amount at 66"
                slug="spousePeraEstimatedBenefitAt66"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse service credits"
                slug="spousePeraServiceCredits"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-8 pt-4">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Social Security
            </h3>
            <div className="mt-2 border-t border-gray-200"></div>
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Benefit amount at 62"
              slug="socialSecurityAmountPartial"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-8 sm:col-span-3">
            <TextField
              name="Benefit amount at full retirement age"
              slug="socialSecurityAmountRetirementAge"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Benefit amount at 70"
              slug="socialSecurityAmountFull"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-0 sm:col-span-1"></div>
        </div>

        {props.values["enterSpouse"] == "Yes" && (
          <div className="grid grid-cols-8 gap-6 pt-4">
            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse benefit amount at 62"
                slug="spouseSocialSecurityAmountPartial"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-3">
              <TextField
                name="Spouse benefit amount at full retirement age"
                slug="spouseSocialSecurityAmountRetirementAge"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse benefit amount at 70"
                slug="spouseSocialSecurityAmountFull"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>

            <div className="col-span-0 sm:col-span-1"></div>
          </div>
        )}

        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-8 pt-4">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Employer Retirement Plan
            </h3>
            <div className="mt-2 border-t border-gray-200"></div>
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Pretax contribution"
              slug="retirementContributionPreTax"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>

          {props.values["currentEmployment"] != "Childrens Hospital" && (
            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Post-tax contribution"
                slug="retirementContributionPostTax"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
              />
            </div>
          )}

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Allocations in stocks"
              slug="retirementContributionStockAllocation"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="401k/403b Balance"
              slug="retirementBalance"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>
        </div>

        {props.values["spouseHasCurrentEmployment"] == "Yes" && (
          <div className="grid grid-cols-8 gap-6 pt-4">
            {!!props.values["spouseRetirementContributionPreTax"] && (
              <div className="col-span-8 sm:col-span-2">
                <TextField
                  name="Spouse pretax contribution"
                  slug="spouseRetirementContributionPreTax"
                  value={props.values}
                  handleChange={props.handleChange}
                  errors={props.errors}
                />
              </div>
            )}

            {!!props.values["spouseRetirementContributionPostTax"] && (
              <div className="col-span-8 sm:col-span-2">
                <TextField
                  name="Spouse posttax contribution"
                  slug="spouseRetirementContributionPostTax"
                  value={props.values}
                  handleChange={props.handleChange}
                  errors={props.errors}
                />
              </div>
            )}

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse allocations in stocks"
                slug="spouseRetirementContributionStockAllocation"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
              />
            </div>

            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse 401k/403b Balance"
                slug="spouseRetirementBalance"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-8 pt-4">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Emergency Reserves
            </h3>
            <div className="mt-2 border-t border-gray-200"></div>
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Checking balance"
              slug="checkingAccountBalance"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Savings balance"
              slug="savingAccountBalance"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Other balances"
              slug="otherAccountBalance"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-0 sm:col-span-2"></div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Extra Cash in Month"
              slug="cashflowExtraPerMonth"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Typical Paycheck"
              slug="checkingAndSavingCleared"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          {props.values["enterSpouse"] == "Yes" && (
            <div className="col-span-8 sm:col-span-2">
              <TextField
                name="Spouse Typical Paycheck"
                slug="spouseCheckingAndSavingCleared"
                value={props.values}
                handleChange={props.handleChange}
                errors={props.errors}
                format="dollars"
              />
            </div>
          )}

          <div className="col-span-0 sm:col-span-2"></div>
        </div>

        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-8 pt-4">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Goals
            </h3>
            <div className="mt-2 border-t border-gray-200"></div>
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Anticipated Retirement Age"
              slug="retireAge"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
            />
          </div>

          <div className="col-span-8 sm:col-span-2">
            <TextField
              name="Retirement Income Needed"
              slug="retireIncome"
              value={props.values}
              handleChange={props.handleChange}
              errors={props.errors}
              format="dollars"
            />
          </div>

          <div className="col-span-0 sm:col-span-4"></div>
        </div>
      </div>

      <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          disabled={props.loading}
          className={
            (props.loading ? "bg-gray-400" : "bg-primary") +
            " inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm"
          }
        >
          <BounceLoader loading={props.loading} size={20} color="#ffffff" />
          <span className={props.loading ? "ml-2" : ""}>Save</span>
        </button>
      </div>
    </form>
  );
}
