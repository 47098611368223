import React, { useState, useEffect, useContext } from "react";
import StyledBounceLoader from "./StyledBounceLoader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

import { formatDollars, roundDollars, getAge } from "./modules/business";
import TenantContext from "../context/tenant";
import useFormData from "../lib/use-form-data";
import useQueryData from "../lib/use-query-data";
import { PensionValue, WealthIndexResponse } from "../lib/types";

ChartJS.register(annotationPlugin);

export default function WealthIndex() {
  const { id } = useContext(TenantContext);
  const { formData: formValues } = useFormData();
  const {
    queryData: { results: wealthIndex },
  } = useQueryData<WealthIndexResponse, any>("wealth_index");
  const {
    queryData: { results: pensionValue },
  } = useQueryData<PensionValue, any>(
    "pension_value",
    id === "1" &&
      (formValues?.hasGreensheet === "Yes" || formValues?.hasGreensheetEstimate === "Yes" ||
        formValues?.hasPeraStatement === "Yes"),
  );

  const {
    queryData: { results: spousePensionValue },
  } = useQueryData<PensionValue, any>(
    "pension_value_spouse",
    id === "1" &&
      (formValues?.spouseHasGreensheet === "Yes" ||
        formValues?.spouseHasPeraStatement === "Yes"),
  );

  const {
    queryData: { results: progressVesting },
  } = useQueryData(
    "dc_progress_vesting", 
    id === "1" &&
      (formValues?.hasGreensheet === "Yes" || formValues?.hasGreensheetEstimate === "Yes" ||
        formValues?.hasPeraStatement === "Yes"),
  );

  const {
    queryData: { results: progressVestingSpouse },
  } = useQueryData(
    "dc_progress_vesting_spouse", 
    id === "1" &&
      (formValues?.spouseHasGreensheet === "Yes" ||
        formValues?.spouseHasPeraStatement === "Yes"),
  );

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Wealth Index",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  const [rangeAnnotation, setRangeAnnotation] = useState({
    type: "box",
    backgroundColor: "rgba(243, 244, 246, .5)",
    borderWidth: 0,
    click: function ({ chart, element }: any) {
      alert("You are doing ok if you are within this range.");
    },
    drawTime: "beforeDatasetsDraw",
    xScaleID: "x",
    xMax: "22",
    xMin: "21",
    yScaleID: "y",
    yMax: 150,
    yMin: 50,
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
  );

  const options: ChartOptions<"line"> = {
    responsive: true,
    scales: {
      y: {
        suggestedMin: -50,
        suggestedMax: 200,
      },
    },
  };

  useEffect(() => {
    if (wealthIndex && wealthIndex.wealthIndexHistory) {
      setChartData({
        // @ts-ignore
        labels: wealthIndex.wealthIndexHistory
          .map((item: { age: number; index: number }) => item.age)
          .sort(),
        datasets: [
          {
            label: "Wealth Index",
            // @ts-ignore
            data: wealthIndex.wealthIndexHistory.map(
              (item: { age: number; index: number }) => item.index * 100,
            ),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });

      let xMin = Object.keys(wealthIndex.wealthIndexHistory)[0];
      let xMax =
        parseInt(Object.keys(wealthIndex.wealthIndexHistory).slice(-1)[0]) + 1;
      setRangeAnnotation({
        ...rangeAnnotation,
        xMax: xMax.toString(),
        xMin: xMin,
      });
    }
  }, [wealthIndex]);

  function getWidth() {
    return window.innerWidth > 768 ? 768 : window.innerWidth;
  }

  function getHeight() {
    return window.innerWidth > 768 ? 440 : window.innerWidth * 0.54;
  }

  function getSpousePensionValueText() {
    if (spousePensionValue) {
      //TODO: handle PERA pensions here
      if (formValues["spouseEmployment"] == "HCMC") {
        if (getAge(formValues["spouseBirthdate"]) >= 65) {
          return (
            <p className="mt-4 px-1 text-sm">
              <>
                Your spouse's current monthly PERA pension benefit is{" "}
                {formatDollars(progressVestingSpouse?.spouseLifeOnlyBenefit)}. An estimated
                future value of their PERA pension is{" "}
                {formatDollars(spousePensionValue.pv_lump_sum)}, which
                contributes {spousePensionValue.npv_wi_contribution}% to your
                Wealth Index.
              </>
            </p>
          );
        }
        return (
          <p className="mt-4 px-1 text-sm">
            <>
              Your spouse's estimated future monthly PERA pension benefit at age
              66 is {formatDollars(spousePensionValue.benefit)}. An estimated
              future lump sum value of their PERA pension is{" "}
              {formatDollars(spousePensionValue.future_lump_sum)}. In today's
              dollars, that is around{" "}
              {formatDollars(spousePensionValue.pv_lump_sum)}, which would
              contribute {spousePensionValue.npv_wi_contribution}% to your
              Wealth Index.
            </>
          </p>
        );
      } else {
        if (getAge(formValues["spouseBirthdate"]) >= 65) {
          return (
            <p className="mt-4 px-1 text-sm">
              <>
                Your spouse's current MNA pension benefit is{" "}
                {formatDollars(progressVestingSpouse?.spouseLifeOnlyBenefit)}. An estimated
                future value of their MNA pension is{" "}
                {formatDollars(spousePensionValue.pv_lump_sum)}, which
                contributes {spousePensionValue.npv_wi_contribution}% to your
                Wealth Index.
              </>
            </p>
          );
        }

        if (
          spousePensionValue?.vesting_age &&
          spousePensionValue?.vesting_age < 65
        ) {
          return (
            <p className="mt-4 px-1 text-sm">
              <>
                Your spouse's estimated future monthly MNA pension benefit at
                their age {spousePensionValue.vesting_age} is{" "}
                {formatDollars(progressVestingSpouse?.spouseLifeOnlyBenefit)} based on
                collecting under the Rule of 85. An estimated future lump sum
                value of their MNA pension is{" "}
                {formatDollars(spousePensionValue.future_lump_sum)}. In today's
                dollars, that is around{" "}
                {formatDollars(spousePensionValue.pv_lump_sum)}, which would
                contribute {spousePensionValue.npv_wi_contribution}% to your
                Wealth Index.
              </>
            </p>
          );
        }

        if (
          spousePensionValue?.vesting_age &&
          spousePensionValue?.vesting_age >= 65
        ) {
          return (
            <p className="mt-4 px-1 text-sm">
              <>
                Your spouse's estimated future MNA pension benefit at age 65 is{" "}
                {formatDollars(progressVestingSpouse?.spouseLifeOnlyBenefit65)}. An
                estimated future lump sum value of their MNA pension is{" "}
                {formatDollars(spousePensionValue.future_lump_sum)}. In today's
                dollars, that is around{" "}
                {formatDollars(spousePensionValue.pv_lump_sum)}, which would
                contribute {spousePensionValue.npv_wi_contribution}% to your
                Wealth Index.
              </>
            </p>
          );
        }
      }
    }

    return null;
  }

  if (!wealthIndex && !formValues) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  return (
    wealthIndex && (
      <div>
        <div className="mx-16 pt-1 text-gray-900">
          <h3 className="text-lg font-medium leading-6">Wealth Index</h3>
          {/* <p className="mt-1 text-sm  text-gray-700 font-semibold pb-4">
                    Time to move to fill in some gaps on the hierarchy of cash flow.
                </p> */}
          <div className="mb-12 mt-2 border-t border-gray-200"></div>
        </div>

        <div className="mx-auto max-w-3xl">
          <div>
            <iframe
              width={getWidth()}
              height={getHeight()}
              src="https://www.youtube.com/embed/d7a51d62Wwo?autoplay=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className="mt-10">
            <p className="px-1 text-sm">
              The wealth index is a measurement that can be used to determine
              how well a household is utilizing their income to create net worth
              during their working years. This, in turn, can be used to help
              determine if a household is on track to reach their long-term
              financial goals, including retirement. It factors in age, income
              and net worth to determine the wealth index and the household's
              accompanying wealth index percentage. This percentage compares the
              household's wealth index to the baseline wealth index. Focus on
              the trend of the percentage going up over time. Update your
              balance sheet annually (assets and debts) to create a trendline.
            </p>
            <p className="mt-4 px-1 text-sm">
              The recommendations of the D&C Financial Program, if followed,
              will automatically help increase your Wealth Index percentage over
              time!
            </p>
          </div>

          <div className="mt-6">
            <h3 className="text-center text-lg font-medium leading-6">
              Your Net Worth is {roundDollars(wealthIndex.netWorth)}. Your
              Wealth Index is {Math.round(wealthIndex.wealthIndex * 100)}%.
            </h3>
          </div>

          <div className="mt-8">
            <p className="px-1 text-sm">
              The Wealth Index, like all other financial measurements, is a tool
              to help gauge your financial progress and needs to factor in other
              financial considerations and measurements. It should not be used
              as a stand-alone measurement to determine whether or not you are
              on track to reach your financial goals!
            </p>
            <p className="mt-4 px-1 text-sm">
              Actions that help improve your Wealth Index % include:
            </p>
            <ul className="ml-8 mt-2 list-disc text-sm">
              <li>Paying down debt</li>
              <li>Increasing reserves</li>
              <li>
                Increasing the percentage amount of your household income you
                contribute to investment accounts (401ks, Roth IRAs, Health
                Savings Accounts, Individual/Joint Investment Accounts, etc.)
              </li>
            </ul>
          </div>

          <div className="mt-6">
            <p className="px-1 text-sm">
              The Wealth Index % early in one's working career is usually not
              very useful from an absolute percentage perspective, so focus on
              the trend early on in your career and follow the recommendations
              of the program to help increase that percentage over time. Once
              you have had a consistent working career (at least 10 years),
              particularly if the income amount is relatively consistent with
              modest increases year-to-year, the following breakdown can be used
              to determine what type of accumulator you are:
            </p>
            <p className="mt-4 px-1 text-center text-sm">
              Wealth Index Percentage 50% or less: Under Accumulator of Wealth
            </p>
            <p className="mt-1 px-1 text-center text-sm">
              Wealth Index Percentage 50% to 150%: Average Accumulator of Wealth
            </p>
            <p className="mt-1 px-1 text-center text-sm">
              Wealth Index Percentage 150% or more: Prolific Accumulator of
              Wealth
            </p>
          </div>

          <div className="mt-10">
            <h3 className="text-center text-lg font-medium leading-6">
              Your Wealth Index History:
            </h3>
          </div>

          <div className="mb-6 mt-12 h-96">
            <Line options={options} data={chartData} />
          </div>

          {id === "1" &&
            pensionValue &&
            formValues["currentEmployment"] != "HCMC" &&
            formValues["currentEmployment"] != "Essentia Health" &&
            (formValues["hasGreensheet"] == "Yes" ||
              formValues["hasGreensheetEstimate"] == "Yes") && (
              <div className="mb-24">
                {getAge(formValues["birthdate"]) >= 65 && (
                  <p className="px-1 text-sm">
                    <>
                      Your current MNA pension benefit is{" "}
                      {formatDollars(progressVesting?.lifeOnlyBenefit)}. An estimated
                      future value of your MNA pension is{" "}
                      {formatDollars(pensionValue.pv_lump_sum)}, which
                      contributes {pensionValue.npv_wi_contribution}% to your
                      Wealth Index.
                    </>
                  </p>
                )}
                {pensionValue?.vesting_age &&
                  pensionValue.vesting_age < 65 &&
                  getAge(formValues["birthdate"]) < 65 && (
                    <p className="px-1 text-sm">
                      <>
                        Your estimated future monthly MNA pension benefit at
                        your age {pensionValue.vesting_age} is{" "}
                        {formatDollars(progressVesting?.lifeOnlyBenefit)} based on
                        collecting under the Rule of 85. An estimated future
                        lump sum value of your MNA pension is{" "}
                        {formatDollars(pensionValue.future_lump_sum)}. In
                        today's dollars, that is around{" "}
                        {formatDollars(pensionValue.pv_lump_sum)}, which would
                        contribute {pensionValue.npv_wi_contribution}% to your
                        Wealth Index.
                      </>
                    </p>
                  )}
                {pensionValue?.vesting_age &&
                  pensionValue.vesting_age >= 65 &&
                  getAge(formValues["birthdate"]) < 65 && (
                    <p className="px-1 text-sm">
                      <>
                        Your estimated future MNA pension benefit at age 65 is{" "}
                        {formatDollars(progressVesting?.lifeOnlyBenefit65)}. An
                        estimated future lump sum value of your MNA pension is{" "}
                        {formatDollars(pensionValue.future_lump_sum)}. In
                        today's dollars, that is around{" "}
                        {formatDollars(pensionValue.pv_lump_sum)}, which would
                        contribute {pensionValue.npv_wi_contribution}% to your
                        Wealth Index.
                      </>
                    </p>
                  )}

                {getSpousePensionValueText()}

                <p className="mt-4 px-1 text-sm">
                  Any estimated MNA pension benefits in this application are for
                  informational purposes only to help illustrate the potential
                  value of your MNA pension. The calculations assume you
                  continue working in the MNA contract to the age of collecting
                  benefits and earn credits roughly equal to your most recent
                  benefit amount for that entire time. Your actual benefit
                  amount will be determined by your work history and will be
                  provided to you by Wilson McShane.
                </p>
              </div>
            )}

          {id === "1" &&
            formValues &&
            formValues["currentEmployment"] == "HCMC" &&
            (formValues["hasPeraStatement"] == "Yes" ||
              formValues["hasPeraEstimate"] == "Yes") && (
              <div className="mb-24">
                {getAge(formValues["birthdate"]) >= 65 &&
                  spousePensionValue && (
                    <p className="mt-4 px-1 text-sm">
                      <>
                        Your current monthly PERA pension benefit is{" "}
                        {formatDollars(formValues.spouseLifeOnlyBenefit)}. An
                        estimated future value of your PERA pension is{" "}
                        {formatDollars(spousePensionValue.pv_lump_sum)}, which
                        contributes {spousePensionValue.npv_wi_contribution}% to
                        your Wealth Index.
                      </>
                    </p>
                  )}
                {getAge(formValues["birthdate"]) < 65 && pensionValue && (
                  <p className="px-1 text-sm">
                    <>
                      Your estimated future monthly PERA pension benefit at your
                      age 66 is {formatDollars(pensionValue.benefit)}. An
                      estimated future lump sum value of your PERA pension is{" "}
                      {formatDollars(pensionValue.future_lump_sum)}. In today's
                      dollars, that is around{" "}
                      {formatDollars(pensionValue.pv_lump_sum)}, which would
                      contribute {pensionValue.npv_wi_contribution}% to your
                      Wealth Index.
                    </>
                  </p>
                )}

                {getSpousePensionValueText()}

                <p className="mt-4 px-1 text-sm">
                  The projections are based on continuous service under PERA up
                  to your age 66. Pension estimates are based on your highest
                  five continuous years of work. Benefits will be taxable as
                  income and may be lower depending on how you claim your
                  pension. Any PERA pension benefit assumptions in this
                  application are for informational purposes only to help
                  illustrate the potential value of your pension. Your actual
                  benefit amount will be determined by your work history and
                  will be provided to you by PERA.
                </p>
              </div>
            )}

          {(formValues["enterSpouse"] == "Yes") &&
            <div className="mb-24">
              <p className="mt-4 px-1 text-xs">
                <>
                  *For married couples, the Wealth Index History chart shows average age of you and your spouse.
                </>
              </p>
            </div>
          }
        </div>
      </div>
    )
  );
}
