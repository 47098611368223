import { useState } from "react";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/esm/entry.vite";

import Modal from "./Modal.js";

const width = {
  DESKTOP: 1200,
  TABLET: 1024,
  MOBILE: 768,
};

export default function PdfModal(props: any) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(props.pdf.page || 1);
  const [changedPageNumber, setChangedPageNumber] = useState(props.pdf.page || 1);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function prevPage() {
    setChangedPageNumber((prev: number) => Math.max(1, prev - 1));
    setPageNumber((prev: number) => Math.max(1, prev - 1));
  }

  function nextPage() {
    setChangedPageNumber((prev: number) => Math.min(numPages || 0, prev + 1));
    setPageNumber((prev: number) => Math.min(numPages || 0, prev + 1));
  }

  function changePage(event: any) {
    const target = event.target;
    let newPageNumber = Math.max(target.value, 1);
    setChangedPageNumber((prev: number) => Math.min(numPages || 0, newPageNumber))

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(setTimeout(function () {
      setPageNumber((prev: number) => Math.min(numPages || 0, newPageNumber));
    }, 1000))
  }

  function getWidth() {
    const innerWidth = window.innerWidth;
    let finalWidth = width.DESKTOP;
    if (innerWidth < width.DESKTOP && innerWidth > width.TABLET) {
      finalWidth = width.TABLET;
    } else if (innerWidth < width.TABLET && innerWidth > width.MOBILE) {
      finalWidth = width.MOBILE;
    } else {
      finalWidth = innerWidth;
    }
    return finalWidth * 0.95;
  }

  function getHeight() {
    return window.innerHeight;
  }

  function getScale() {
    return 1;
  }

  return (
    <Modal show={props.showModal} handleClose={props.handleClose}>
      <div className="page-scroll bg-white">
        <div>
          <Document file={props.pdf.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              height={getHeight()}
              width={getWidth()}
              scale={getScale()}
            />
          </Document>

          <div className="my-2 text-center">
            <p>
              Page 
              <input name="pageNumber" value={changedPageNumber} onChange={changePage} 
                className="w-8 mx-2 shadow-sm rounded-md border-primary ring-1 ring-primary"
              />
              of {numPages}
            </p>
            
            <div className="mx-auto inline-block">
              <ul className="my-2 flex list-none rounded pl-0">
                <li className="relative ml-0 block rounded-l border border-r-0 border-gray-300 bg-white px-3 py-2 leading-tight text-blue-700 hover:bg-gray-200 cursor-pointer">
                  <a className="page-link" onClick={() => prevPage()}>
                    Previous
                  </a>
                </li>
                {pageNumber > 1 && (
                  <li className="relative block border border-r-0 border-gray-300 bg-white px-3 py-2 leading-tight text-blue-700 hover:bg-gray-200 cursor-pointer">
                    <a className="page-link" onClick={() => prevPage()}>
                      {pageNumber - 1}
                    </a>
                  </li>
                )}
                <li className="relative block border border-r-0 border-gray-300 bg-white px-3 py-2 leading-tight text-blue-700 hover:bg-gray-200 cursor-pointer">
                  <a
                    className="page-link"
                    onClick={() => setPageNumber(pageNumber)}
                  >
                    {pageNumber}
                  </a>
                </li>
                {pageNumber < (numPages || 0) && (
                  <li className="relative block border border-r-0 border-gray-300 bg-white px-3 py-2 leading-tight text-blue-700 hover:bg-gray-200 cursor-pointer">
                    <a className="page-link" onClick={() => nextPage()}>
                      {pageNumber + 1}
                    </a>
                  </li>
                )}
                <li className="relative block rounded-r border border-gray-300 bg-white px-3 py-2 leading-tight text-blue-700 hover:bg-gray-200 cursor-pointer">
                  <a className="page-link" onClick={() => nextPage()}>
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
