import React, { useState, useEffect, useContext } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useRouteError,
} from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Header from "./Header";
import AppFlow from "./pages/Inverview";
import Main from "./pages/Main";
import Forms from "./pages/Forms";
import Puzzle from "./pages/Puzzle";
import Learn from "./pages/Learn";
import Contact from "./pages/Contact";
import Admin from "./pages/Admin";
import StyledBounceLoader from "./StyledBounceLoader";
import Welcome from "./pages/Welcome";
import NotFound from "./pages/NotFound";
import * as Sentry from "@sentry/browser";
import DashboardLayout from "./pages/DashboardLayout";
import Overview from "./Overview";
import WealthIndex from "./WealthIndex";
import CashFlow from "./CashFlow";
import DashboardRecommendation from "./DashboardRecommendation";
import { SWRConfig } from "swr";
import { defaultOnErrorRetry } from "../lib/error";
import ApiErrorModal from "./ApiErrorModal";

export default function App() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email });
    }
  }, [user]);

  if (isLoading) {
    return (
      <div className="mx-auto my-16 max-w-5xl">
        <div className="flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  return (
    <SWRConfig
      value={{
        onError: (error: any, key: any, { errorRetryCount }) => {
          setShowErrorModal(true);

          console.error(error);
          console.log(key);
        },
        onErrorRetry: defaultOnErrorRetry,
        revalidateOnMount: true,
      }}
    >
      <ApiErrorModal
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
      />
      <Routes>
        <Route path={"/"} element={<Layout />} errorElement={<ErrorElement />}>
          <Route
            path="/"
            index
            element={
              !isAuthenticated ? <Main /> : <Navigate to={"/interview"} />
            }
          />
          <Route
            path="/interview"
            element={<AuthGuard component={Welcome} />}
          />
          <Route
            path="/interview/:section"
            element={<AuthGuard component={AppFlow} />}
          />
          <Route path="/puzzle" element={<AuthGuard component={Puzzle} />} />
          <Route path="/forms" element={<AuthGuard component={Forms} />} />
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route
              path="/dashboard"
              element={<Navigate to={"/dashboard/overview"} />}
            />
            <Route
              path="/dashboard/overview"
              element={<AuthGuard component={Overview} />}
            />
            <Route
              index
              path="/dashboard/wealth-index"
              element={<AuthGuard component={WealthIndex} />}
            />
            <Route
              index
              path="/dashboard/cash-flow"
              element={<AuthGuard component={CashFlow} />}
            />
            <Route
              index
              path="/dashboard/recommendations"
              element={<AuthGuard component={DashboardRecommendation} />}
            />
          </Route>
          <Route path="/help" element={<AuthGuard component={Learn} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </SWRConfig>
  );
}

const Layout = ({}) => {
  return (
    <div className="max-w-full bg-gray-50">
      <Header />
      <div className="main-body mx-auto">
        <div className="pt-12">
          <Outlet />
        </div>
      </div>

      <footer className="top-2 bg-gray-100 text-center lg:text-left">
        <div className="p-4 text-center text-sm text-gray-700">
          Copyright © 2023 Dorval & Chorne Financial Advisors.
          <div className="text-xs">
            Quality of Life Planning is a registered trademark of Dorval &
            Chorne Financial Advisors. All Rights Reserved
          </div>
          <div className="text-xs">
            Privacy Policy: Dorval and Chorne Financial Advisors does not
            collect your data for sale to any third party. Our development team
            may track your movement through the application in order to improve
            performance and our Advisors may read your responses in order to
            better serve you.
          </div>
        </div>
      </footer>
    </div>
  );
};

const ErrorElement = ({}) => {
  const error = useRouteError();

  console.log(error);

  return <h1>Oh no!</h1>;
};

function AuthGuard({ component, options, ...props }: any) {
  const { pathname } = useLocation();
  const ProtectedComponent = withAuthenticationRequired(component, {
    ...options,
    returnTo: pathname,
  });
  return <ProtectedComponent {...props} />;
}
